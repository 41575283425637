import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <p>{`Make `}<a parentName="p" {...{
        "href": "https://github.com/axios/axios"
      }}>{`axios`}</a>{` requests in React using hooks.`}</p>
    <ul>
      <li parentName="ul">{`Manages your request state so you can focus on building your UI`}</li>
      <li parentName="ul">{`Accepts standard `}<a parentName="li" {...{
          "href": "https://github.com/axios/axios#request-config"
        }}>{`axios request config`}</a>{` so writing requests feels familiar`}</li>
      <li parentName="ul">{`Provides type definitions and generic functions so you can write typesafe code`}</li>
      <li parentName="ul">{`No superficial "blazing fast" claims or esoteric emojis`}</li>
    </ul>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`with npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ npm install use-axios-client
`}</code></pre>
    <p>{`with yarn:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ yarn add use-axios-client
`}</code></pre>
    <p><em parentName="p"><em parentName="em">{`Note`}</em>{`: Requires that you use React 16.8.0 or higher.`}</em></p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`Here is a simple example of using the `}<a parentName="p" {...{
        "href": "/use-axios"
      }}>{`useAxios`}</a>{` hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useAxios } from 'use-axios-client';

export default () => {
  const { data, error, loading } = useAxios({
    url: 'https://example/api',
  });

  return (
    <>
      {loading && <div>Loading...</div>}
      {error && <div>{error.message}</div>}
      {data && <div>{data}</div>}
    </>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      